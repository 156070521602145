/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';

/* Services */

/**
 * Renders the MSR SectionTitle component.
 *
 * @param titleObj.titleObj
 * @param {object} titleObj component.
 * @param {object} rest some more data
 * @param titleObj.pageName
 * @returns {ReactElement} MSR SectionTitle component.
 */
const MsrSectionTitle = ({ titleObj, pageName, ...rest }) => {
  const titleColor = {
    'no-decoration': '#FFA735',
    'drums-above': 'white.50',
    'white-no-decoration': '#FFF7E9',
  };

  const textAlign = {
    'no-decoration': 'center',
    'drums-above': 'center',
    'white-no-decoration': 'left',
  };

  const maxW = {
    'no-decoration': { base: '100%', md: '515px' },
    'drums-above': { base: '100%', md: '515px', lg: '600px', xl: '770px' },
    'white-no-decoration': {
      xl: '1140px',
      lg: '754px',
      md: '754px',
      sm: '388px',
      base: '314px'
    }
  };

  const fontSize = {
    'no-decoration': { base: '26px', md: '48px' },
    'drums-above': { base: '26px', md: '40px' },
    'white-no-decoration': { base: '26px', md: '40px' },
  };

  const fontFamily = {
    'no-decoration': 'FedraSerifAStdBook',
    'drums-above': 'FedraSansStd-A-medium',
    'white-no-decoration': 'FedraSansStd-A-medium',
  };
  // const mx = {
  //   'no-decoration': 'auto',
  //   'drums-above': 'auto',
  //   'white-no-decoration': '0',
  // };
  
  return (
    <Box
      maxW={rest.w || maxW[titleObj?.titleDecoration]}
      w={rest.w || '100%'}
      // mx={mx[titleObj?.titleDecoration]}
      mx="auto"
      fontFamily={fontFamily[titleObj?.titleDecoration]}
      fontSize={{
        base: titleObj?.fontSize || fontSize[titleObj?.titleDecoration]?.base,
        md: titleObj?.fontSize || fontSize[titleObj?.titleDecoration]?.md,
      }}
      lineHeight="1.2"
      color={
        titleObj?.color || titleColor[titleObj?.titleDecoration] 
      }
      textAlign={textAlign[titleObj?.titleDecoration]}
      mt={{
        base: rest.mt || '30px',
        md: rest.mt || '30px',
      }}
      pt={{
        base: rest.pt || '0',
        md: rest.pt || '0',
      }}
      className="titleWrapper"
    >
      {RenderBorder(titleObj?.titleDecoration) && (
        <Box textAlign="center" pb={'15px'}>
          {RenderBorder(titleObj?.titleDecoration)}
        </Box>
      )}

      <Box
        as={rest.as || 'h2'}
        m={{ base: '0 auto' }}
        mb={titleObj?.titleDecoration === 'white-no-decoration' ? '0' : '15px'}
        px={{ base: '10px', md: 0 }}
        maxW={rest.w || maxW[titleObj?.titleDecoration]}
      >
        {titleObj?.title || titleObj?.titleText}
        {titleObj?.titleSubtext && (
          <Box
            fontSize={{ base: titleObj?.titleDecoration === 'no-decoration' ? '20px' : '16px', md: titleObj?.titleDecoration === 'no-decoration' ? '26px' : '16px' }}
            lineHeight="30px"
            textAlign="center"
            mt={titleObj?.titleDecoration === 'no-decoration' ? '6px' : '10px'}
            color={titleObj?.titleDecoration === 'no-decoration' ? '#FFFFFF' : '#FFA735'}
          >
            {titleObj?.titleSubtext}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const RenderBorder = style => {
  style = style || 'default';
  const imgPath = {
    'no-decoration': '',
    'drums-above': '1664431985-drums-vector.png',
    'white-no-decoration': '',
  };

  return (
    imgPath[style] && (
      <LazyLoadImageComponent
        alt="divider"
        title="divider"
        minHeight="20px"
        src={
          imgPath[style]?.indexOf('https://') < 0
            ? `${config.staticPath}/d/46272/${imgPath[style]}`
            : imgPath[style]
        }
      />
    )
  );
};

export default MsrSectionTitle;
