/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Heading, Link } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import { numFormatter, refineUrlDomain } from '@msr/components/Utility/utils';

/* Services */

/**
 *
 * @param {Object} content
 * @returns
 */
const ShivaBlockCard = ({ content, lang, page }) => {
  const contentType = {
    video: `${config.imgPath}/d/46272/1664431945-video-play.png`,
    audio: `${config.imgPath}/d/46272/1664431963-songs.png`,
    article: `${config.imgPath}/d/46272/1664431942-article.png`,
    poem: `${config.imgPath}/d/46272/1664431938-poem.png`,
  };
  const likeCountLimit = 100;

  return (
    <Box
      display="flex"
      flexDir={{ base: 'row', md: 'column' }}
      gridGap={{ base: '0', md: '20px' }}
      height="100%"
    >
      <Box>
        <NextLink
          href={refineUrlDomain(
            content?.url || content?.urlAlias,
            '',
            lang,
            page || 'shiva'
          )}
          passHref
          legacyBehavior>
          <Link
            position="relative"
            zIndex="1"
            cursor={content?.url || content?.urlAlias ? 'cursor' : 'auto'}
          >
            <LazyLoadImageComponent
              w={{ base: '108px', md: '370px' }}
              maxW={{ base: '108px', md: '370px' }}
              h={{ base: '100%', md: '223px' }}
              src={content.cardImage?.url}
              alt={content.cardImage?.alt}
              title={content.cardImage?.title}
              objectFit="cover"
            />
          </Link>
        </NextLink>
      </Box>
      <Box
        px={{ base: '16px', md: '20px' }}
        pb={{ base: '9px', md: '20px' }}
        pt={{ base: '12px', md: '0' }}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <Heading
          as="h3"
          fontSize={{ base: '18px !important', md: '26px !important' }}
          mb={{ base: '0px !important', md: '26px !important' }}
          color="#FFF7E9"
          w={{ base: '86%', md: 'auto' }}
          overflow={{ base: 'hidden' }}
          textOverflow={{ base: 'ellipsis' }}
          display={{ base: '-webkit-box', md: 'block' }}
          sx={{
            '-webkit-line-clamp': '2',
            '-webkit-box-orient': 'vertical',
          }}
          lineHeight={{ base: '28px' }}
        >
          <NextLink
            href={refineUrlDomain(
              content?.url || content?.urlAlias,
              '',
              lang,
              page || 'shiva'
            )}
            passHref
            legacyBehavior>
            <Link
              position="relative"
              zIndex="1"
              cursor={content?.url || content?.urlAlias ? 'cursor' : 'auto'}
              _hover={{
                textDecoration: 'none',
              }}
            >
              {content?.title}
            </Link>
          </NextLink>
        </Heading>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDir="row"
          color="#C1C3DD"
        >
          <Box
            display="flex"
            fontSize={{ base: '11px', md: '16px' }}
            gridGap="16px"
            alignItems="center"
          >
            {content.likestotalcount > likeCountLimit && (
              <Box display="flex" gridGap="8px" alignItems="center">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.25 16.707V8.45703M1.5 9.95703V15.207C1.5 16.0355 2.17157 16.707 3 16.707H13.0697C14.1803 16.707 15.1246 15.8968 15.2935 14.7992L16.1012 9.54918C16.3109 8.18621 15.2564 6.95703 13.8774 6.95703H11.25C10.8358 6.95703 10.5 6.62124 10.5 6.20703V3.55641C10.5 2.53502 9.672 1.70703 8.65065 1.70703C8.40697 1.70703 8.18625 1.85051 8.08733 2.07312L5.44795 8.01161C5.32758 8.28251 5.05899 8.45703 4.7626 8.45703H3C2.17157 8.45703 1.5 9.12858 1.5 9.95703Z"
                    stroke="#C1C3DD"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {numFormatter(content.likestotalcount)}
              </Box>
            )}
            {/* <Box
              display="flex"
              gridGap="8px"
              alignItems={{ base: 'flex-end', md: 'center' }}
            >
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7914 10.3133C19.0355 10.104 19.1575 9.99937 19.2023 9.87487C19.2415 9.76567 19.2415 9.64607 19.2023 9.53687C19.1575 9.41237 19.0355 9.30767 18.7914 9.09847L10.3206 1.83783C9.9004 1.47763 9.6903 1.29753 9.5124 1.29312C9.3578 1.28929 9.2101 1.35721 9.1124 1.47709C9 1.61502 9 1.89176 9 2.44523V6.74049C6.86532 7.11394 4.91159 8.19557 3.45971 9.81977C1.87682 11.5904 1.00123 13.8818 1 16.2569V16.8688C2.04934 15.6048 3.35951 14.5824 4.84076 13.8718C6.1467 13.2453 7.55842 12.8742 9 12.7764V16.9665C9 17.52 9 17.7967 9.1124 17.9347C9.2101 18.0545 9.3578 18.1225 9.5124 18.1186C9.6903 18.1142 9.9004 17.9341 10.3206 17.5739L18.7914 10.3133Z"
                  stroke="#C1C3DD"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              0
            </Box> */}
          </Box>
          <Box display="flex">
            <LazyLoadImageComponent
              src={
                contentType[content.contentType] || contentType[content.types]
              }
              alt={content.contentType || content.types}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ShivaBlockCard;
