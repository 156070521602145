/* Built In Imports */
/* External Imports */
import { Box, Heading, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/* Services */

/**
 * Renders MSR Top Banner Simple Component
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - data from api
 * @returns MSR Top Banner Simple Component
 */
const MsrTopBanner = ({ sectionContent }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const objArrows = {
    pos: 'absolute',
    top: '50%',
    zIndex: '10',
    cursor: 'pointer',
    bgSize: '20px',
    w: '46px',
    h: '46px',
    boxShadow: '0 0 7px 0 rgb(0 0 0 / 13%)',
    bgPos: 'center',
    bgRepeat: 'no-repeat',
    color: 'transparent',
  };
  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        {...objArrows}
        bgImage={`${config.imgPath}/d/46272/1664373605-vector-3.png`}
        //bgImage={`url(${config.staticPath}/assets/images/left-arrow.svg)`}
        left={{ base: '2%' }}
        borderRadius={{ base: '50%', md: '0', lg: '0' }}
        onClick={onClick}
      ></Box>
    );
  };
  const NextArrow = props => {
    const { onClick } = props;
    return (
      <Box
        {...objArrows}
        //bgImage={`url(${config.staticPath}/assets/images/right-arrow.svg)`}
        bgImage={`${config.imgPath}/d/46272/1664373600-vector-2.png`}
        right={{ base: '2%' }}
        borderRadius={{ base: '50%', md: '0', lg: '0' }}
        onClick={onClick}
      ></Box>
    );
  };

  const settings = {
    dots: sectionContent.style !== 'auto-slider-fade-in',
    dotsClass: 'slick-dots',
    autoplay: true,
    arrows: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    nextArrow:
      sectionContent.style === 'auto-slider-fade-in' ? <></> : <NextArrow />,
    prevArrow:
      sectionContent.style === 'auto-slider-fade-in' ? <></> : <PrevArrow />,
    customPaging: function () {
      return (
        <Text
          cursor="pointer"
          display="inline-block"
          w="12px"
          h="12px"
          m="0px 8px"
          bgColor="rgba(255, 255, 255, 0.69)"
          borderRadius="50%"
        />
      );
    },
  };

  if (
    sectionContent?.desktopImage?.length > 1 ||
    sectionContent?.mobileImages?.length > 1
  ) {
    return (
      <Box>
        <Slider
          // key={index}
          {...settings}
          className="bannerHfix"
        >
          {!isMobile &&
            Array.isArray(sectionContent?.desktopImage) &&
            sectionContent?.desktopImage.map(img => (
              <Box key={nanoid()}>
                <LazyLoadImageComponent
                  alt={img?.alt}
                  w="full"
                  src={img?.url}
                />
              </Box>
            ))}
          {!isMobile &&
            !Array.isArray(sectionContent?.desktopImage) &&
            sectionContent?.desktopImage.map(img => (
              <Box key={nanoid()}>
                <LazyLoadImageComponent
                  alt={img?.alt}
                  title={img?.title}
                  w="full"
                  src={img?.url}
                />
              </Box>
            ))}
          {isMobile &&
            Array.isArray(sectionContent?.mobileImages) &&
            sectionContent?.mobileImages?.map(img => (
              <Box key={nanoid()}>
                <LazyLoadImageComponent
                  alt={img?.alt}
                  title={img?.title}
                  w="full"
                  src={img?.url}
                />
              </Box>
            ))}
          {isMobile && !Array.isArray(sectionContent?.mobileImages) && (
            <Box key={nanoid()}>
              <LazyLoadImageComponent
                alt={sectionContent?.mobileImages?.alt}
                title={sectionContent?.mobileImages?.title}
                w="full"
                src={sectionContent?.mobileImages?.url}
              />
            </Box>
          )}
        </Slider>
        <style>
          {`
          @media(max-width:1199px) {
            .bannerHfix .slick-list {
              height:auto !important;
            } 
          }
          .bannerHfix .slick-list {
            height:75vh;
          } 
          .bannerHfix .slick-dots{
          bottom: 5%;
          }
        
          .bannerHfix .slick-dots li p:hover {
            background-color: #FFFFFF;
          }
          .bannerHfix .slick-dots li.slick-active p {
            background-color: #FFFFFF;
          }
        }
     
        `}
        </style>
      </Box>
    );
  }

  if (sectionContent?.style === 'short-left-align') {
    return (
      <Box
        backgroundImage={{
          base:
            sectionContent?.mobileImages[0]?.url ||
            sectionContent?.desktopImage[0]?.url,
          md: sectionContent?.desktopImage[0]?.url,
        }}
        backgroundSize={{ base: '100%', md: 'cover' }}
        backgroundPosition="top"
        backgroundColor="black"
        backgroundRepeat="no-repeat"
        width="100%"
        position="relative"
        height={{ md: '300px', sm: '300px', base: '180px' }}
        color="#FFF7E9"
        fontSize="48px"
        py={{ base: '20px', md: '0' }}
        px="10%"
      >
        <Box
          height="100%"
          display="flex"
          alignItems="baseline"
          justifyContent="center"
          flexDir="column"
          py={{ base: '15px', md: '81px' }}
          mx="auto"
          maxW="1140px"
        >
          <Heading
            as="h1"
            // mb="20px"
            fontSize={{ base: '26px', md: '48px' }}
            p={{ base: '0', md: '0' }}
            m="16px 0 8px"
            maxW="632px"
            fontWeight="350"
            className="topBannerTitle"
          >
            {sectionContent?.title}
          </Heading>

          <Box display="flex">
            <StructuredTextParser
              id="titleDescription"
              str={render(sectionContent?.description)}
              textAlign="left"
              fontSize="16px"
              fontFamily="FedraSansStd-book"
              color="white"
              theme="light"
            />
          </Box>
        </Box>
        <style global jsx>
          {`
            #titleDescription p {
              margin: 0 auto;
            }
          `}
        </style>
      </Box>
    );
  } else if (sectionContent?.style === 'short-center-align') {
    return (
      <Box
        backgroundImage={{
          md: sectionContent?.desktopImage[0]?.url,
          base:
            sectionContent?.mobileImages?.url ||
            sectionContent?.desktopImage[0]?.url,
        }}
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundColor="#000"
        backgroundRepeat="no-repeat"
        width="100%"
        position="relative"
        height={{ base: '300px' }}
        color="#FFA735"
        fontSize="48px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        py={{ base: '50px', md: '0' }}
        px="10%"
        mb={{ base: '30px', lg: '60px' }}
      >
        <Box px={{ base: '55px', md: '0' }} py="81px" maxW="1140px">
          <Heading
            as="h1"
            mb="20px"
            fontSize={{ base: '26px', md: '48px' }}
            p={{ base: '0', md: '16px 0' }}
            m="0"
            maxW="632px"
            fontWeight="300"
          >
            {sectionContent?.title}
          </Heading>

          <Box>
            <StructuredTextParser
              str={render(sectionContent?.description)}
              textAlign="left"
              fontSize="16px"
              fontFamily="FedraSansStd-book"
              color="white"
              theme="light"
            />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      backgroundImage={{
        md: sectionContent?.desktopImage[0]?.url,
        base:
          sectionContent?.mobileImages?.url ||
          sectionContent?.desktopImage[0]?.url,
      }}
      backgroundSize="cover"
      backgroundPosition="center top"
      backgroundColor="#000"
      backgroundRepeat="no-repeat"
      width="100%"
      position="relative"
      height={{ base: '220px', md: '70vh', lg: '75vh' }}
      color="#FFF7E9"
      fontSize="48px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDir="column"
      py={{ base: '50px', md: '0' }}
    >
      <Box px={{ base: '10%', md: '22%' }} maxW="1140px" textAlign="center">
        <Heading as="h1" mb="20px" fontSize={{ base: '26px', md: '48px' }}>
          {sectionContent?.title}
        </Heading>

        <StructuredTextParser
          str={render(sectionContent?.description)}
          textAlign="center"
          theme="light"
          fontFamily="FedraSansStd-book"
        />
      </Box>
    </Box>
  );
};

export default MsrTopBanner;
